.gradientfooterbg {
    /*
      Variables are defined right along style declarations.
      They're indicated by the double-hyphen prefix.
    */
    --color-1: rgb(167, 212, 156);
    --color-2: rgb(0, 106, 227);
    /*
      You can access variables using the 'var()' function:
    */
    background: linear-gradient(170deg, var(--color-1), var(--color-2));
  }
  .footertext{
      text-align: center;
      padding: 20px;
      color: aliceblue;
  }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


    .gradientbg {
        /*
          Variables are defined right along style declarations.
          They're indicated by the double-hyphen prefix.
        */
        --color-1: rgba(167, 212, 156, 0.635);
        --color-2: rgba(0, 106, 227, 0.575);
        min-height: 80vh;
        /*
          You can access variables using the 'var()' function:
        */
        background: linear-gradient(170deg, var(--color-1), var(--color-2));
      }
      .gradient100 {
        /*
          Variables are defined right along style declarations.
          They're indicated by the double-hyphen prefix.
        */
        --color-1: rgb(167, 212, 156);
        --color-2: rgb(0, 106, 227);
        /*
          You can access variables using the 'var()' function:
        */
        background: linear-gradient(170deg, var(--color-1), var(--color-2));
        border-radius:  20px 20px 20px 20px;
        text-align: center;
        
    }
.allbodybg{
    background: white;
    border-radius: 30px 30px 30px 30px;

    padding-left:0px;
    padding-right: 0px
  }
      .textcolorwhite{color: aliceblue;}
      .Link
      {text-decoration: none;
        color: black;
        padding: 15px;
        font-size: 20px;
        border-bottom: 3px solid rgb(255, 255, 255);
    }
    .Link:hover
      {text-decoration: none;
        color: black;
        padding: 15px;
        font-size: 20px;
        background-color: rgb(32, 188, 255);
        border-bottom: 3px solid rgb(42, 1, 167);
        color: white;
    }
    .loginbtn{
        background-color: rgb(3, 177, 3);
        color: white;
        text-decoration: none;
        padding: 5px 30px;
        border-radius: 20px 20px 20px 20px;
    }
    .loginbtn:hover{
        background-color: rgb(4, 224, 4);
        color: white;
        text-decoration: none;
        padding: 5px 30px;
        border-radius: 20px 20px 20px 20px;
    }
   

.Header_headerbody__2ff88{
    
    background-color: rgb(255, 255, 255);
    padding: 0px;
    box-shadow: 5px 5px 10px;
}
.Header_dashbordusericon__1kuuq{
    font-size: 20px;
    float: left;
}

*{
    padding:0px;
    margin: 0%;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}
.Header_Navbar__yHPhv{margin-bottom: 20px;
padding: 0px;
background-color: rgb(255, 255, 255);
    box-shadow: 5px 5px 10px;
}
.Header_mainnav__3KABN{
width: 100%;
font-size: 15px;

}
.Header_logo__1xMdQ{
    width: 20%;
}

.Header_Linkdropdown__kmJxo{ padding: 8px 18px;font-size: 16px; }
.Header_subLink__3zkwu{ text-decoration: none; padding: 15px;
text-align: center;color: black;
width: 100%; font-size: 12px;}

.Header_menulink__3OYlD{
    width: 60%;
}
.Header_menulogin__1H9Dd{
    width: 20%;
}

.Header_menulinkul__hWDFv{

display: -webkit-flex;
}
.Header_menulinkli__1zKKn{
    padding: 20px;
}
.Header_menulinklia__R0J6W{
   text-decoration: none;
   color: black; 

}
.Footer_gradientfooterbg__2LVGm {
    /*
      Variables are defined right along style declarations.
      They're indicated by the double-hyphen prefix.
    */
    --color-1: rgb(167, 212, 156);
    --color-2: rgb(0, 106, 227);
    /*
      You can access variables using the 'var()' function:
    */
    background: linear-gradient(170deg, var(--color-1), var(--color-2));
  }
  .Footer_footertext__2n9qO{
      text-align: center;
      padding: 20px;
      color: aliceblue;
  }
.body_loginbody__1aRA_{ margin: 80px 0px 20px 0px; padding: 20px;}
.body_signupbody__N5s_P{ margin: 20px; padding: 20px;}
.body_usericonarea__1N8q_{
   margin-top: 30px;
}

.body_usericon__uRjgK{
font-size: 40px;
color: rgb(189, 230, 255);
}
.body_loginarealeft__AQOJW{font-size: 30px;}
.body_loginsignuptitle__HtVg5{
    color: white;
    font-size: 17px;
}
.body_inputitmdiv__HUhlH{
    margin: 30px;
}
.body_inputitmdivsignup__1POGk{
    margin: 20px;
}

.body_inputitm__3jGSK{padding: 5px;
font-size: 20px;
border-radius: 50px 50px;
background-color: white;
border: none;
text-align: center;
width: 100%;
box-shadow: 2px 2px 5px;}

.body_inputitmbtn__tSSvB{padding: 5px;
    margin-top: 30px;
    font-size: 20px;
    border-radius: 50px 50px;
    border: none;
    text-align: center;
    width: 50%;
    background: rgb(122, 203, 1);
    color: white;
}
.body_inputitmbtnsignup__GjhPV{
    padding: 5px;
    margin-top: 7px;
    font-size: 20px;
    border-radius: 50px 50px;
    border: none;
    text-align: center;
    width: 50%;
    background: rgb(122, 203, 1);
    color: white;
}

.body_remeber__3nASK{float: left;
    padding: 5px;
    color: rgb(195, 235, 250);}
.body_forgot__3bVN1{float: right;
    padding: 5px;
    color: rgb(195, 235, 250);}
.body_loginbuttomarea__oXEG-{
    color: rgb(145, 255, 224);
    padding: 20px 0px;
}

/* Dailyreportbody */

.body_bodyheaderleft__2Nx4S{
    /*
          Variables are defined right along style declarations.
          They're indicated by the double-hyphen prefix.
        */
        --color-2: rgb(167, 212, 156);
        --color-1: rgb(0, 106, 227);
        /*
          You can access variables using the 'var()' function:
        */
        background: linear-gradient(170deg, var(--color-1), var(--color-2));

    width: 50%;
    float: left;
    border-radius: 30px 0px 50px 0px;
    padding: 15px;
    font-size: 25px;
    color: white;
    
}

.body_bodyheaderright__28YaT{
    width: 50%;
    float: right;
    padding: 12px;
}
.body_inputdate__LwCCH{
    width: 100%;
    padding: 5px;
    font-size: 20px;
    border-radius: 10px 10px 10px 10px;
}
.body_inputmonthlydate__20uAs{
    width: 45%;
    padding: 5px;
    margin: 2%;
    font-size: 20px;
    border-radius: 10px 10px 10px 10px;
}

.body_bodyMreportLeft__20J53{ 
    width: 40%;
    text-align: center;
    font-size: 22px;
    float: left;
    padding: 20px;
}
.body_bodyMreportMid__LiYIB{
    width: 30%;
    text-align: center;
    font-size: 22px;
    float: left;
    padding: 20px;
}
.body_bodyMreportRight__1zE1k{
    width: 30%;
    text-align: center;
    font-size: 22px;
    float: right;
    padding: 20px;
}

.body_bodyleftprev__14FEF{
    border: 2px solid goldenrod;


border-radius: 0px 20px 20px 0px;
padding: 5px;
color: blue;
    width: 49%;
    text-align: center;
    font-size: 20px;
    float: right;
    height:45px;
    cursor: pointer;
}
.body_bodyleftprev__14FEF:hover{
    border: 2px solid rgb(66, 218, 32)
}
.body_bodyrightnext__1aWjr{
    border: 2px solid goldenrod;

    border-radius: 20px 0px 0px 20px;
    padding: 5px;
    color: blue;
        width: 49%;
        text-align: center;
        font-size: 20px;
        float: left;
        height:45px;
        cursor: pointer;
}
.body_bodyrightnext__1aWjr:hover{
    border: 2px solid rgb(66, 218, 32)
}
.body_bodyleft__1FCch{
    width: 50%;
    text-align: center;
    font-size: 20px;
    float: left;
    padding: 20px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.body_bodyright__K54B7{
    width: 50%;
    text-align: center;
    font-size: 20px;
    float: right;
    padding: 20px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.body_frombodyleftfirst__epaXW{
    width: 50%;
    text-align: center;
    font-size: 17px;
    float: left;
    background: rgb(0, 149, 255);
    /*padding: 10px;*/
    color: white;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;
    border-radius: 30px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
}
.body_frombodyrightfirst__nFfmi{
    width: 50%;
    text-align: center;
    font-size: 17px;
    float: right;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;
    border-radius: 0px 30px 0px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
}
.body_frombodyleftfirst40__10wlh{
    width: 40%;
    text-align: center;
    font-size: 17px;
    float: left;
    background: rgb(0, 149, 255);
    /*padding: 10px;*/
    color: white;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;
    border-radius: 30px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
}
.body_frombodyrightfirst60__1jUMq{
    width: 60%;
    text-align: center;
    font-size: 17px;
    float: right;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;
    border-radius: 0px 30px 0px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
}
.body_frombodyleft__29FfX{
    width: 50%;
    text-align: center;
    font-size: 17px;
    float: left;
    background: rgb(0, 149, 255);
    /*padding: 10px;*/
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;
    height: 74px;
  
}
.body_frombodyleft40__2yFB0{
    width: 40%;
    text-align: center;
    font-size: 17px;
    float: left;
    background: rgb(0, 149, 255);
    /*padding: 10px;*/
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;
    height: 74px;
  
}
.body_frombodyleft30__1PU1B{
    width: 30%;
    text-align: center;
    font-size: 17px;
    float: left;
    background: rgb(0, 149, 255);
    padding: 10px;
    color: white;
    text-align: center;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
}
.body_MReportbodyRight__3MCwh{
    width: 50%;
    text-align: center;
    font-size: 20px;
    float: right;
    margin-bottom: 8px;
    margin-top: 8px;
}
.body_MReportbodyLeft__11oMm{
    width: 50%;
    text-align: center;
    font-size: 20px;
    float: left;
    margin-bottom: 8px;
    margin-top: 8px;
   
}
.body_MReportbodyLeft20__1ZVH3{
    width: 20%;
    height: 51px;
    text-align: center;
    font-size: 20px;
    float: left;
    background: #80808040;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
}


.body_frombodyright__LP-p4{
    width: 50%;
    text-align: center;
    font-size: 17px;
    float: right;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
   
}
.body_frombodyright60__3xsb6{
    width: 60%;
    text-align: center;
    font-size: 17px;
    float: right;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
   
}
.body_frombodyright30__16yLb{
    width: 30%;
    text-align: center;
    font-size: 18px;
    float: right;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;
   
}
.body_inputitmbtnsavearea__2EQmK{
    padding: 30px 20% 30px 20%;
    min-width: 200px;
    margin-top: 20px;
}
.body_inputitmbtnsave__1pQ8-{
    width: 100%;
    padding: 10px;
    font-size: 20px;
    background: rgb(0, 157, 255);
    border: none;
    border-radius: 50px 50px 50px 50px;

}
.body_inputitem__1bUSR{
    width: 100%;
    padding:10px 10%;
    background-color: gainsboro;
    border: none;
    height: 100%;
}

.body_inputitemT__1le57{
    width: 100%;
    padding:10px 5%;
    background-color: gainsboro;
    border: none;
    height: 100%;
}

.body_inputitem20__3Ul1E{
    width: 100%;
    background: none;
    border: none;
    padding: 10px 10%;
    text-align: center;
    font-size: 17px;
    height: 100%;
}
.body_inputitem25__V5jPe{
    width: 100%;
    background: none;
    border: none;
    padding: 0px;
    text-align: center;
    font-size: 25px;
    height: 100%;
}



.body_inputitemfirst__1bOE_{
    width: 100%;
    padding:10px 10% 10px 10%;
    background-color: gainsboro;
    border: none;
    border-radius: 0px 30px 0px 0px;
    height: 100%;
}
.body_fromarea__2kR0r{padding: 20px 5%;
    margin-bottom: 10px;
    margin-top: 10px;
    height: 75px;
}
.body_prevnextarea__3_1_z{padding: 0px 0%;
    margin-bottom: 0px;
    margin-top: 0px;
    height: 50px;
}

.body_inputitemtextarea__3pPXW{
    padding: 10px;
    width: 100%;
    border: 2px solid rgb(0, 157, 255);
}
.body_inputitemcheckbox__3BsuU{
    height: 41px;
}

/* Accomplishment */

.body_bodyleftprogress__3-U2I{
        width: 37%;
        text-align: center;
        font-size: 22px;
        float: left;
        padding: 20px;
}
.body_bodycenterprogress__3fejY{
    width: 18%;
    text-align: center;
    font-size: 22px;
    float: left;
    padding: 20px;
}
.body_bodyrightprogress__32gin{
    width: 27%;
    text-align: center;
    font-size: 22px;
    float: left;
    padding: 20px;
}
.body_frombodyleftprogress__z3IUe{
    width: 40%;
    text-align: center;
    font-size: 17px;
    float: left;
    background: rgb(0, 149, 255);
    padding: 10px;
    color: white;
    text-align: center;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
}
.body_frombodycenterprogress__1LWin{
    width: 30%;
    text-align: center;
    font-size: 17px;
    float: left;
    background: rgb(224, 230, 234);
    padding: 10px;
    color: black;
    text-align: center;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
}
.body_frombodyrightprogress__2u9Ji{
    width: 30%;
    font-size: 17px;
    float: left;
    background: rgb(255, 255, 255);
    padding: 2px 5px;
    color: black;
    text-align: center;
    border: 2px solid rgb(0, 149, 255);
    margin-bottom: 10px;
    height: 74px;
}

/* content */
.body_bodysection__380Dq{  
    margin: 100px 0px 40px 0px; 
}
.body_bodybg__25kyu{
    background: white;
    padding: 0px;
    border-radius: 30px 30px 30px 30px;
}
.body_bodyconarea__1VhfC{
    width: 100%;
}
.body_contuctarea__3gd4X{padding: 30px;
text-align: left;
font-size: 17px;}

.body_socialmedia1__25kgP{ 
    color: rgb(0, 34, 255);
    font-size: 30px;
    float: left;
    margin: 5px;
    text-align: center;
    border-radius: 50px 50px 50px 50px;
}
.body_socialmedia2__3R2JM{ 
    color: rgb(0, 200, 255);
    float: left;
    font-size: 30px;
    margin: 5px;
    text-align: center;
    border-radius: 50px 50px 50px 50px;
}
.body_socialmedia3__2sGWi{ 
    color: rgb(0, 255, 38);
    font-size: 30px;
    float: left;
    margin: 5px;
    text-align: center;
    border-radius: 50px 50px 50px 50px;
}
.body_socialmedia4__3QEHj{ 
    color: red;
    font-size: 30px;
    float: left;
    margin: 5px;
    text-align: center;
    border-radius: 50px 50px 50px 50px;
}

.body_feedbackarea__26J7v{
    padding: 10px 10% ;
    text-align: center;
    background: rgb(0, 115, 255);
    color: white;
    border-radius: 30px 30px 0px 0px;
    margin: 0px 20px;
}
.body_feedbackbtn__kSNsm{
    background: rgb(0, 202, 0);
    color: white;
    border-radius: 50px 50px 50px 50px;
    width: 100%;
    padding: 10px;
    font-size: 22px;
    margin-bottom: 30px;
    border: none;
}
.body_inputitmfeedback__3A9ki{
    padding: 10px;
    font-size: 20px;
    border: 1px solid black;
    width: 100%;
}

/* Book name */


.body_totalprogress__qAacZ{
font-size: 22px;
text-align: center;
padding: 10px 10px;
width: 100%;
}
.body_totalprogressbar__1-L69{
    margin:10px 10%;
}

/* counselor name */
.body_counselor__VVg29{
    float: left;
    padding: 0px 30px;
    width: 100%;
}
.body_counselorright__2bqAW{
float: right;
padding: 20px;
width: 100%;
}
.body_counselorrightinput__2Bd_8{
    padding: 10px;
    width: 100%;
}
.body_advicesname__wlRep{
    padding: 10px;

}
.body_seeadvices__2b_Hg{
    padding: 5px 10px;
    background: rgb(1, 109, 233);
    color: white;
    float: right;
    font-size: 17px;
    border-radius: 50px 50px;
    margin-top: 4px;
}
.body_seemore__25A3B{
    padding: 5px 10px;
    background: rgba(8, 190, 23, 0.792);
    color: white;
    float: right;
    font-size: 17px;
    border-radius: 50px 50px;
    margin-top: 2px;
}
.body_counselorlist__2oFLm{
    border-top: 1px solid rgb(199, 199, 199);
    padding: 5px 0px;
}
.body_lastadviser__SUozh{
    padding:10px 10px;
    margin: 2px 20px;
    background: rgb(11, 0, 213);
    color: white;
    font-size: 20px;
}
.body_lastadviserlist__2EMJl{
   
    padding: 4px 5px;
    margin: 3px 5px ;
    background: #ffffff;
    text-align: justify;
}

.body_PageNotFound__1Mnew{
    font-size: 70px;text-align: center;
    padding: 50px;
  }
  .body_bodytopheight__Y2NpI{
      height: 75px;
  }
  .body_textrotate90__1N0G8{
    transform: rotate(-90deg);
-webkit-transform: rotate(-90deg);  /* Safari */
-moz-transform: rotate(-90deg);  /* Firefox */
-o-transform: rotate(-90deg);  /* Opera */
-ms-transform: rotate(-90deg);  /* IE (Internet Expl0rer) */
-webkit-transform-origin: left top;
        transform-origin: left top;
position:-webkit-sticky;
bottom: 0;
left: 5px;
}
.body_cardIcon__1JQ5D{
    font-size: 100px;
    margin-top: 50px;
    text-align: center;
    width: 100%;
}
.body_cardTitle__1CMu5{
text-align: center;
}
.body_cardbody__3ksXA{
text-align: justify;
}
.body_scrollable__1ZsxN {
    background-color: #f1f1f1;
    /*height: 800px;*/
    overflow: auto;
    margin: 20px;
    text-align: justify;
    padding: 0;
  }
.body_accountInfut__b37BO{
border:none;
font-size: 20px;
padding:5px;
width:100%;
text-align:center;
}
